import { useMemo } from "react";
import { useMatches } from "@remix-run/react";

export function useMatchesData(id, isExpected = true) {
  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id],
  );

  if (isExpected && !route) {
    throw new Error(
      `Expected route "${id}" to be loaded. Ensure this route is a parent of this invocation.`,
    );
  }

  return route?.data;
}
